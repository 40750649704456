import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  inject, OnInit,
} from '@angular/core';
import {
  faArrowDown,
  faArrowUp,
  faCircleCheck,
  faCircleXmark,
  faExclamationTriangle,
  faEye,
  faUserTie,
} from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';
import { BaseUser, UserType } from 'src/app/shared/models';
import {
  PolicyUpdate,
  PolicyUpdateStatus,
} from 'src/app/shared/models/classes/policy-update';
import { AuthService } from 'src/app/shared/services/auth.service';
import { selectPermissionsGranted } from 'src/app/shared/store/permission-granted/+state/permission-granted.selectors';
import { carrierMap } from '../../commission-statistics/commission-statistics/carrier';
import { PolicyUpdateEditModalComponent } from './policy-update-edit-modal/policy-update-edit-modal.component';
import { PolicyUpdateService } from 'src/app/shared/services/policy-update.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AgencyTagComponent } from '../../ui-components/agency-tag/agency-tag.component';
import { policyUpdateStatusMap } from 'src/app/shared/data/policy-update';
import {
  ModalContainerService,
  ShowModalComponents,
} from 'src/app/shared/services/modal-container.service';
import { TooltipComponent } from '../../ui-components/tooltip/tooltip.component';

@Component({
  selector: 'app-policy-update-table',
  standalone: true,
  imports: [
    AgencyTagComponent,
    AsyncPipe,
    DatePipe,
    FaIconComponent,
    PolicyUpdateEditModalComponent,
    TooltipComponent,
  ],
  providers: [CurrencyPipe],
  templateUrl: './policy-update-table.component.html',
  styleUrl: './policy-update-table.component.scss',
})
export class PolicyUpdateTableComponent implements OnInit {
  private authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);
  private currencyPipe = inject(CurrencyPipe);
  private modalContainerService = inject(ModalContainerService);
  private policyUpdateService = inject(PolicyUpdateService);
  private store = inject(Store);

  faArrowDown = faArrowDown;
  faArrowUp = faArrowUp;
  faEye = faEye;
  faExclamationTriangle = faExclamationTriangle;
  faUserTie = faUserTie;

  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;

  carrierMap = carrierMap;
  policyUpdateStatusMap = policyUpdateStatusMap;
  PolicyUpdateStatus = PolicyUpdateStatus;

  keys?: any;

  sortBy?: {
    key: string;
    direction: 'asc' | 'desc';
    isInverted: boolean;
  } = {
    key: 'agen1t',
    direction: 'asc',
    isInverted: false,
  };

  toggleSort(key: string, isInverted = false) {
    if (this.sortBy?.key === key) {
      this.sortBy = {
        key: this.sortBy.key,
        direction: this.sortBy.direction === 'asc' ? 'desc' : 'asc',
        isInverted,
      };
    } else {
      this.sortBy = {
        key,
        direction: 'asc',
        isInverted,
      };
    }
    this.cdr.detectChanges();
  }

  @Input() showAllProfiles?: boolean;
  @Input() data$?: Observable<{
    loading: false;
    policyUpdates: PolicyUpdate[] | null;
    policyUpdate: PolicyUpdate | null;
    error: null;
  }>;

  // @ViewChild('policyUpdatesEditModal')
  // policyUpdatesEditModal?: PolicyUpdateEditModalComponent;

  agencyMap: { [key: string]: BaseUser } = {};

  dateToday = new Date().toISOString().split('T')[0];

  permissionsGrantedSubscription?: Subscription;
  ngOnInit() {
    this.authService.currentUser$
      .pipe(takeWhile((u) => !u.userType, true))
      .subscribe((user) => {
        if (user.userType) {
          const keys: {
            id: string;
            title: string;
            key?: string[];
            primitive?: boolean;
            type?: 'CURRENCY' | 'DATE';
          }[] = [
            {
              id: 'agent',
              title: 'Agent',
            },
            {
              id: 'agent-npn',
              title: 'Agent NPN',
              key: ['agent', 'npn'],
              // primitive: true
            },
            {
              id: 'agency',
              title: 'Agency',
            },
            {
              id: 'carrier',
              title: 'Carrier',
            },
            {
              id: 'id',
              title: 'Policy ID',
            },
            {
              id: 'type',
              title: 'Plan Type',
              key: ['planTypePolicyUpdate'],
            },
            {
              id: 'state',
              title: 'State',
              key: ['state'],
              primitive: true,
            },
            {
              id: 'effectiveDate',
              title: 'Effective Date',
              key: ['effectiveDate'],
              primitive: true,
              type: 'DATE',
            },
            {
              id: 'statementDate',
              title: 'Statement Date',
              key: ['statementDate'],
              primitive: true,
              type: 'DATE',
            },
          ];

          // if (user.userType === UserType.AGENCY) {
          //   keys.push({
          //     id: 'policyUpdate',
          //     title: 'Payout',
          //     key: ['payout', 'personal'],
          //     // key: ['policyUpdates', 'amountAgency'],
          //     primitive: true,
          //     type: 'CURRENCY',
          //   });
          //   keys.push({
          //     id: 'policyUpdate',
          //     title: 'PolicyUpdate Agent',
          //     key: ['payout', 'total'],
          //     // key: ['policyUpdates', 'amountAgent'],
          //     primitive: true,
          //     type: 'CURRENCY',
          //   });
          // } else {
          keys.push({
            id: 'policyUpdate',
            title: 'PolicyUpdate',
            key: ['policyUpdateAmount'],
            // key: ['policyUpdates', 'amountAgent'],
            primitive: true,
            type: 'CURRENCY',
          });

          keys.push({
            id: 'paymentDescription',
            title: 'Payment Description',
            key: ['identifiedResult', 'operationMessage'],
            // primitive: true,
          });

          this.keys = keys;
        }
      });

    this.permissionsGrantedSubscription = this.store
      .select(selectPermissionsGranted)
      .subscribe((permissions) => {
        if (permissions.permissionsGranted) {
          for (const permission of permissions.permissionsGranted) {
            if (
              permission.user &&
              permission.user.id &&
              permission.user.userType === UserType.AGENCY
            )
              this.agencyMap[permission.user.id] = permission.user;
          }
        }
      });
  }

  ngOnDestroy() {
    this.permissionsGrantedSubscription?.unsubscribe();
  }

  getValue(data: any, k: any) {
    let res;
    if (k.key) {
      if (k.key.length === 3) {
        res = data[k.key[0]]?.[k.key[1]]?.[k.key[2]];
      } else if (k.key.length === 2) {
        if (k.type === 'DATE') {
          const date = new Date(data[k.key[0]]?.[k.key[1]]?.timestamp);

          return `${
            date.getMonth() + 1
          }/${date.getDate()}/${date.getFullYear()}`;
        } else {
          res = data[k.key[0]]?.[k.key[1]];
        }
      }
      if (k.key.length === 1) {
        if (k.type === 'DATE') {
          if (data[k.key[0]]?.timestamp) {
            const date = new Date(data[k.key[0]]?.timestamp);

            return `${
              date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()}`;
          }
        }
        res = data[k.key[0]];
      }
    }

    if (k.type === 'CURRENCY') {
      return this.currencyPipe.transform(res);
    }
    return res;
  }

  getId(id: string) {
    const split = id.split('de-');

    if (split.length === 2) {
      return split[1];
    }
    return split[0];
  }

  editPolicyUpdate(policyUpdate: PolicyUpdate) {
    console.log('editPolicyUpdate', policyUpdate);
    // this.policyUpdatesEditModal?.open(policyUpdate);
    this.modalContainerService
      .open(ShowModalComponents.POLICY_UPDATE, policyUpdate)
      ?.subscribe((res) => {
        console.log('res', res);
        if (res?.eventName === 'retry') {
          this.retry(res.data);
        }
      });
  }

  retry(policyUpdate: PolicyUpdate) {
    console.log('retry', policyUpdate);
    this.policyUpdateService.retryPolicyUpdate(policyUpdate);
  }
}
