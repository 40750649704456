@if (keys) {
  @if (data$ | async; as state) {
    <div class="card">
      @if (state.policyUpdates; as data) {
        <div class="card-body">
          <!-- <div *ngIf="agents.error">
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
          <h3 class="my-4">An error occurred</h3>
          <p>{{ agents.error }}</p>
        </div> -->
          @if (data && data.length > 0) {
            <div class="overflow-x-auto">
              <table class="table table-sm table-pin-cols">
                <thead>
                  <tr>
                    <th>Created at</th>
                    <td>Group Id</td>
                    <td>Commission</td>
                    <td>Enrollment</td>
                    <td>Statement Date</td>
                    <td>Status</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  @for (d of data; track d) {
                    <tr>
                      <th>
                        {{ d.createdAt?.timestamp | date }}
                      </th>
                      <td>{{ d?.identifiers?.identifierGroupId }}</td>
                      <td>
                        @if (d?.commission) {
                          <fa-icon [icon]="faCircleCheck"></fa-icon>
                        } @else {
                          <fa-icon [icon]="faCircleXmark"></fa-icon>
                        }
                      </td>
                      <td>
                        @if (d?.enrollment) {
                          <fa-icon [icon]="faCircleCheck"></fa-icon>
                        } @else {
                          <fa-icon [icon]="faCircleXmark"></fa-icon>
                        }
                      </td>
                      <td>
                        @if (d.commission?.cycle?.name) {
                          <span class="badge badge-outline h-auto rounded-xl">{{
                            d.commission?.cycle?.name
                          }}</span>
                        }
                      </td>
                      <td>
                        @if (d.status) {
                          @if (policyUpdateStatusMap[d.status]; as type) {
                            <span
                              class="badge gap-2 rounded-xl h-auto"
                              [class.bg-secondary]="type.color === 'secondary'"
                              [class.bg-accent]="type.color === 'accent'"
                              [class.bg-primary]="type.color === 'primary'"
                              [class.bg-red-500]="type.color === 'red'"
                              [class.bg-yellow-500]="type.color === 'yellow'"
                              [class.bg-green-500]="type.color === 'green'"
                              [class.bg-purple-500]="type.color === 'purple'"
                              [class.bg-gray-500]="type.color === 'gray'"
                              [class.text-white]="
                                type.color === 'primary' ||
                                type.color === 'green' ||
                                type.color === 'purple'
                              "
                            >
                              @if (type.icon) {
                                <fa-icon [icon]="type.icon"></fa-icon>
                              }
                              {{ type.title }}
                            </span>
                          }
                        }
                        @if (d.status === PolicyUpdateStatus.US_ERROR) {
                          <span>
                            {{ d.error?.message }}
                          </span>
                        }
                      </td>
                      <td>
                        <app-tooltip text="View Policy Update">
                          <button
                            type="button"
                            class="btn btn-circle btn-ghost"
                            (click)="editPolicyUpdate(d)"
                          >
                            <fa-icon [icon]="faEye"></fa-icon>
                          </button>
                        </app-tooltip>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
          @if (data && data.length === 0) {
            <p>No Policy Updates found</p>
          }
        </div>
      }
    </div>
  }
}

<!-- <app-policy-update-edit-modal
#policyUpdatesEditModal
(retryClicked)="retry($event)"></app-policy-update-edit-modal> -->
